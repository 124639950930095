import React from 'react';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { Box } from '@mui/material';

/*---CVT---*/
import { Button } from '@conventic-web/internal.cvt.basic.button';
import { CvtColorSchema, CvtBasicTheme } from '@conventic-web/internal.global';
import { HtmlContent } from '@conventic-web/internal.basic.html-content';
import { useWindowDimensions, slugify } from '@conventic-web/internal.utility';
import { BlockTop } from '@conventic-web/internal.cvt.layout.block-top';
import { BlockStandard } from '@conventic-web/internal.cvt.layout.block-standard';
import { Hexagon } from '@conventic-web/internal.cvt.data-display.hexagon';

/*---Global---*/
import SYSTEM_STRINGS from '../../utility/data/system/systemStrings.yaml';
import {
  IconComponents,
  PaddingObj,
  DiagonalObj,
} from '../../global/sharedObjects';

/*---Components---*/
import Layout from '../../components/skeleton_page/layout';
import Seo from '../../components/skeleton_page/seo';

/*---Images---*/
import ImgBlog from '../../images/Pics/pic_blog_stage.svg';

type BlogPostProps = {
  author: string;
  title: string;
  content: string;
  seoTitle: string;
  seoDescription: string;
  seoKeywords: any;
  categories: string;
  featuredImage: any;
};

const BlogPost: React.FC<BlogPostProps> = ({
  author,
  title,
  content,
  seoTitle,
  seoDescription,
  seoKeywords,
  categories,
  featuredImage,
}: BlogPostProps) => {
  const styles = {
    containerHexagon: {
      display: 'inline-block',
      position: 'absolute',
      zIndex: 20,
      top: '22em',
      left: ' 14.5vw',
    },

    btnAlleBeitraege: {
      position: 'absolute',
      zIndex: 50,
      top: '8em',
      left: '10%',
    },
    hexagon: {
      base: {
        color: CvtColorSchema.white,
        fontSize: 70,
      },
    },
  } as const;
  const location = useLocation();
  const { width } = useWindowDimensions();

  let icon: any, slug: string;
  switch (categories) {
    case 'technology':
      icon = IconComponents.Terminal;
      slug = SYSTEM_STRINGS.LINKS.INTERN.BLOG.TECHNOLOGY;
      break;
    case 'news':
      icon = IconComponents.InsertComment;
      slug = SYSTEM_STRINGS.LINKS.INTERN.BLOG.NEWS;
      break;
    case 'consulting':
      icon = IconComponents.AssignmentTurnedIn;
      slug = SYSTEM_STRINGS.LINKS.INTERN.BLOG.BERATUNG;
      break;
    default:
      icon = IconComponents.LocalLibrary;
      slug = SYSTEM_STRINGS.LINKS.INTERN.BLOG.BLOG;
      break;
  }

  return (
    <Layout>
      <Seo
        title={slugify(title)}
        keywords={seoKeywords}
        description={seoDescription}
        image={featuredImage}
        pathname={location.pathname}
      />
      <BlockTop
        headline="Blog"
        subheadline="dranbleiben!"
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgImage={ImgBlog}
        zIndex={8}
        padding={PaddingObj.PaddingStarter}
        color={CvtColorSchema.fontDarkGray}
      />
      <Box component="div" sx={styles.btnAlleBeitraege}>
        <Link to={SYSTEM_STRINGS.LINKS.INTERN.BLOG.MAIN}>
          <Button
            bgColor={CvtColorSchema.yellow}
            color={CvtColorSchema.fontDarkGray}
            text="Alle Beiträge"
            hoverColor={CvtColorSchema.green}
            name={'Standardbutton'}
            type={'button'}
            disabled={false}
          />
        </Link>
      </Box>
      <Box component="div" sx={styles.containerHexagon}>
        <Link to={slug}>
          <Hexagon
            componentIcon={icon}
            bgColor={CvtColorSchema.yellow}
            styleComponentIcon={styles.hexagon.base}
            size={4.5}
            alt={categories}
            clickable={true}
            invert={false}
            rotate={false}
            margin={'30%'}
          />
        </Link>
      </Box>
      <BlockStandard
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        padding={
          width > 1024
            ? PaddingObj.PaddingSinglePostBlog
            : PaddingObj.PaddingSinglePostBlogSmall
        }
        color={CvtColorSchema.fontDarkGray}
        bgColor={CvtColorSchema.white}
        zIndex={8}
      >
        <span>{author}</span>
        <h1>{title}</h1>
        <HtmlContent
          dangerouslySetInnerHTML={content}
          style={CvtBasicTheme.htmlContent.general}
        />
      </BlockStandard>
    </Layout>
  );
};

/**
 * @description Returns a single blogpage using .md.
 * @see /templates/singleBlogPostPage.js for further information.
 */

export default BlogPost;
